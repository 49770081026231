/**
 * вся информация про текущего залогиненного пользователя
 *
 *
 */
import { observable, action} from 'mobx';
import urlJoin from "url-join";
import axios from "axios";
import { basicAuthHeaders } from 'GUI_MAIN/Utils/basicAuthHeaders';
import { config } from 'config'
import _get from "lodash/get";
import { PractitionerRoleAppRoles } from "MODEL_STORE/MIRAMEDIX/Models/PractitionerRole/PractitionerRoleAppRoles";


export const AUTH_TOKEN_STORAGE_KEY = "authToken";
export const REFRESH_TOKEN_STORAGE_KEY = "refreshToken";
export const ACTIVE_ROLE_ID_STORAGE_KEY = "activeRoleId";

export class AuthStoreDataClass {

    /**
     *
     */
    @observable
    ready;

    @observable
    selectRole;

    @observable
    user;

    @observable
    person;

    @observable
    roles;

    @observable
    organizations;

    @observable
    permission;

    @observable
    active_role_id;

    @observable
    active_organization_id;

    @observable
    resetPasswordRequestActivityEmailFieldError;

    @observable
    resetPasswordRequestError;

    @observable
    resetPasswordRequestInProgress;

    @observable
    inputForChangePassword1;

    @observable
    inputForChangePassword2;

    @observable
    inputForChangePasswordError;

    @observable
    needReloadSession = false;

    constructor() {
        this.ready = false;
        this.selectRole = false;
        this.user = null;
        this.person = null;
        this.active_role_id = null;
        this.active_organization_id = null;
        this.roles = [];
        this.organizations = [];
        this.resetPasswordRequestError = null;
        this.resetPasswordRequestInProgress = false;
        this.resetPasswordRequestActivityEmailFieldError = false;
        this.inputForChangePassword1 = '';
        this.inputForChangePassword2 = '';
        this.inputForChangePasswordError = null;
    }

    /**
     *
     */
    @action
    setReady = data => {
        this.ready = data;
    }

    /**
     *
     */
    @action
    setSelectRole = data => {
        this.selectRole = data;
    }

    @action
    setUser = data => {
        this.user = data;
    }

    @action
    setPerson = data => {
        this.person = data;
    }

    @action
    setRoles = data => {
        this.roles = data;
    }

    @action
    setOrganizations = data => {
        this.organizations = data;
    }

    @action
    setPermission = data => {
        this.permission = data;
    }

    @action
    setActiveRoleId = data => {
        this.active_role_id = data;
    }

    @action
    setActiveOrganizationId = data => {
        this.active_organization_id = data;
    }


    @action
    setResetPasswordRequestError = data => {
        this.resetPasswordRequestError = data;
    }

    @action
    setResetPasswordRequestInProgress = data => {
        this.resetPasswordRequestInProgress = data;
    }

    @action
    setPasswordRequestActivityEmailFieldError = data => {
        this.resetPasswordRequestActivityEmailFieldError = data;
    }

    @action
    setInputForChangePassword1 = data => {
        this.inputForChangePassword1 = data;
    }

    @action
    setInputForChangePassword2 = data => {
        this.inputForChangePassword2 = data;
    }

    @action
    setInputForChangePasswordError = data => {
        this.inputForChangePasswordError = data;
    }

    // prevent two simultaneous refreshes
    nowRefreshing = false;

    /**
     *
     */
    @action
    clear = () => {
        window.localStorage.removeItem(AUTH_TOKEN_STORAGE_KEY);
        window.localStorage.removeItem(REFRESH_TOKEN_STORAGE_KEY);
        window.sessionStorage.removeItem(AUTH_TOKEN_STORAGE_KEY);
        window.localStorage.removeItem(ACTIVE_ROLE_ID_STORAGE_KEY);
        window.sessionStorage.removeItem(ACTIVE_ROLE_ID_STORAGE_KEY);


        this.ready = false;
        this.selectRole = false;
        this.user = null;
        this.person = null;
        this.active_role_id = null;
        this.active_organization_id = null;
        this.roles = [];
        this.organizations = [];

        this.resetPasswordRequestError = null;
        this.resetPasswordRequestInProgress = false;
        this.resetPasswordRequestActivityEmailFieldError = false;
        this.inputForChangePassword1 = '';
        this.inputForChangePassword2 = '';
        this.inputForChangePasswordError = null;
    }

    authHeaders() {

        let token = this.loadAccessToken();

        if (token) {
            return {
                'Authorization' : 'Bearer ' + token
            };
        }

        return null;
    }

    get activeRole() {
        return this.roles.find(i => i.id === this.active_role_id);
    }

    /**
     *
     */
    getToken(login, password) {
        const data = {
            username: login.toLowerCase(),
            password: password,
            grant_type: 'password',
        };

        // тут мы не используем обертку из-за циклической зависимости
        return axios
            .post(
                urlJoin(config.AIDBOX_BASE_URL, "/auth/token"),
                data,
                {
                    headers: basicAuthHeaders(),
                }
            )
            .then(response => {
                this.saveAuthToken(response.data.access_token);
                this.saveRefreshToken(response.data.refresh_token);
            })
            .catch( error => {
                console.warn('auth error');
                console.warn(error);
                throw error;
            })
            ;
    }

    /**
     *
     */
    refreshToken = async (options = {}) => {

        const traceparent = options.traceparent;

        try {

            const refreshToken = this.loadRefreshToken();
            if (!refreshToken) {
                throw new Error("no refresh token, please login again");
            }

            // здесь мы сознательно делаем запрос без использования обертки axiosMaximus
            // из-за неё циклическая зависимость получается, а функционал тот не нужен
            const response = await axios.post(
                urlJoin(config.AIDBOX_BASE_URL, "/openid/refresh-token"),
                {
                    "refresh_token": refreshToken,
                },
                {
                    headers: {
                        "traceparent": traceparent,
                    },
                }
            );

            const newAuthToken = response.data.access_token;
            const newRefreshToken = response.data.refresh_token;

            this.saveAuthToken(newAuthToken);
            this.saveRefreshToken(newRefreshToken);

            return true;

        } catch (error) {
            // в любой непонятной ситуации тупо очищаем все токены и ~делаем вид. что так и было~ отправляем на авторизацию
            console.log("REFRESH ERROR:", error);
            this.clear();

            return false;
        }
    }

    /**
     *  извлекаем из JWT поле sub
     */
    jwtExtractField = (token, field) => {
        const jwtRegex = /^[-+_\/=A-Za-z0-9]+\.[-+_\/=A-Za-z0-9]+\.[-+_\/A-Za-z0-9]+$/;
        if (!jwtRegex.test(token)) {
            return undefined;
        }
        try {
            const payloadRaw = token.split('.')[1]
            const payloadEncoded = payloadRaw.replace(/-/g, '+').replace(/_/g, '/');
            const payloadStr = atob(payloadEncoded)
            const payload = JSON.parse(payloadStr);
            return payload[field];
        } catch (e) {
            console.error("seems we got correct token, but cannot extract SUB:" + e);
            return undefined;
        }
    }

    /**
     * загружаем сохраненный токен из хранилища
     */
    loadAccessToken = () => {

        let resultToken = undefined;
        let needReload = false;

        // сразу сверяем выбранную роль - по ней должно быть аналогичное поведение
        const globalActiveRole = window.localStorage.getItem(ACTIVE_ROLE_ID_STORAGE_KEY);
        const sessionActiveRole = window.sessionStorage.getItem(ACTIVE_ROLE_ID_STORAGE_KEY);

        // сверяем только если ОБЕ существуют, то есть оба ключа заполнены
        // пришла роль и есть уже сохраненная
        if (
            (globalActiveRole !== null)
            &&
            (sessionActiveRole !== null)
            &&
            (globalActiveRole != sessionActiveRole)
        ) {
            console.warn(`active role changed from ${sessionActiveRole} to ${globalActiveRole}`);
            needReload = true;
        }

        const globalToken = window.localStorage.getItem(AUTH_TOKEN_STORAGE_KEY);
        const sessionToken = window.sessionStorage.getItem(AUTH_TOKEN_STORAGE_KEY);

        // иногда попадаются странные вещи, типа пустой строки - они нам не нужны
        if (globalToken) {
            resultToken = globalToken;
        }

        if (!sessionToken && globalToken) {
            window.sessionStorage.setItem(AUTH_TOKEN_STORAGE_KEY, globalToken);
            sessionToken = globalToken;
        }

        const globalSub = this.jwtExtractField(globalToken, "sub");
        const sessionSub = this.jwtExtractField(sessionToken, "sub");

        // оба будут undefined в случае логина суперадмина
        if (globalSub != sessionSub) {
            console.warn(`authenticated user changed from ${sessionSub} to ${globalSub}`);
            needReload = true;
        }

        // следует обратить внимание, что обратно в false поставить нельзя - только перезагрузкой
        if (needReload) {
            this.needReloadSession = true;
            // если не очистить, успеет среагировать после обновления страницы до прихода userinfo
            window.sessionStorage.removeItem(ACTIVE_ROLE_ID_STORAGE_KEY);
            return undefined;
        }

        return resultToken;
    }

    /**
     *
     */
    loadRefreshToken = () => {

        // sometime we find string "undefined" living in localstorage, so now I try to catch it
        const storedValue = window.localStorage.getItem(REFRESH_TOKEN_STORAGE_KEY);
        if (storedValue == "undefined") {
            console.warn("got 'undefined' (string) in refresh token!");
            return undefined;
        }
        return storedValue;
    }

    /**
     * сохраняем данные в хранилище, оттуда они будут использоваться в запросах
     */
    saveAuthToken = (authToken) => {

        window.localStorage.setItem(AUTH_TOKEN_STORAGE_KEY, authToken);
        window.sessionStorage.setItem(AUTH_TOKEN_STORAGE_KEY, authToken);
    }

    /**
     *
     */
    saveRefreshToken = (refreshToken) => {
        if (refreshToken == "undefined") {
            console.warn("--------------------------------------------");
            console.warn("HEY! we got an 'undefined' as refresh token!");
            console.warn("--------------------------------------------");
        }
        if (refreshToken) {
            window.localStorage.setItem(REFRESH_TOKEN_STORAGE_KEY, refreshToken);
        } else {
            window.localStorage.removeItem(REFRESH_TOKEN_STORAGE_KEY);
        }
    };

    /**
     * проверка наличия у пользователя определенных прав
     */
    userHasAccessRights = (object, action, includes) => {
        let right = `${object}_${action}`;
        if (includes)
            right = `${right}_${includes}`;
        return !!this.permission.find(perm => perm === right);
    };

    /**
     * есть ли у активной роли некоторая арм-роль
     */
    activeRoleHasArmRole = (arm_role) => {
        const active = new PractitionerRoleAppRoles(this.activeRole);
        return active.hasReference(arm_role);
    };

    /**
     * является ли пользователь super-администратором?
     */
    isRoot = () => {
        return _get(this.user, "_data.data.isAdmin", false);
    };

    /**
     * Прошел ли пользователя оба этапа авторизации
     */
    isFullLogin = () => {
        return !!this.active_role_id;
    };

    /**
     * у текущего пользователя есть одна единственная роль эксперта?
     */
    get userHasExpertArmAccessRoleOnly() {
        const accessRoles = _get(this.user, "_data.data.accessRole", []);
        return accessRoles.length == 1 && accessRoles[0] == "arm_expert";
    }

    /**
     * у текущего пользователя есть одна единственная роль лечащего врача?
     */
    get userHasPractitionerArmAccessRoleOnly() {
        const accessRoles = _get(this.user, "_data.data.accessRole", []);
        return accessRoles.length == 1 && accessRoles[0] == "arm_practitioner";
    }

    /**
     * идентификаторы организаций, на которые ссылаются роли текущего сотрудника
     */
    get practitionerRoleOrganizationIdentifiers() {
        return Array.isArray(this.organizations) ? this.organizations.map(org => org.id) : [];
        //Старая реализация
        /*return Array.isArray(this.roles) ? this.roles.map(role => {
            return _get(role, "organization.id");
        }).filter(id => id) : [];*/
    }
}

const AuthStoreData = new AuthStoreDataClass();

export { AuthStoreData }
