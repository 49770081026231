import { backendRequestPost } from "MODEL_STORE/DataSource/Requests/backendRequestPost";

class Logger {
  static showApplication = (data = {}) =>
    backendRequestPost("/logging/show/application", data);

  static showPresentationtop = (data = {}) =>
    backendRequestPost("/logging/show/presentation", data);

  static showProtocol = (data = {}) =>
    backendRequestPost("/logging/show/protocol", data);

  static showConsilium = (data = {}) =>
    backendRequestPost("/logging/show/consilium", data);

  static showDicomDocument = (data = {}) =>
    backendRequestPost("/logging/show/dicom", data);

  static createDicomDocument = (data = {}) =>
    backendRequestPost("/logging/create/dicom", data);

  static showPatient = (data = {}) =>
    backendRequestPost("/logging/show/patient", data);

  static showPatientSelection = () =>
    backendRequestPost("/logging/show/patientSelection", {});

  static showAdditionalPersonalData = (data = {}) =>
    backendRequestPost("/logging/show/additionalPersonalData", data);

  static deleteApplication = (data = {}) =>
    backendRequestPost("/logging/delete/application", data);

  static createConsilium = (data = {}) =>
    backendRequestPost("/logging/create/consilium", data);

  static listConsilium = (data = {}) =>
    backendRequestPost("/logging/list/consilium", data);

  static updateConsilium = (data = {}) =>
    backendRequestPost("/logging/update/consilium", data);

  static deleteСonsilium = (data = {}) =>
    backendRequestPost("/logging/delete/consilium", data);

  static statusChangeTeamParticipants = (data = {}) =>
    backendRequestPost("/logging/statusChange/teamParticipants", data);

  static createPatient = (data = {}) =>
    backendRequestPost("/logging/create/patient", data);

  static updatePatient = (data = {}) =>
    backendRequestPost("/logging/update/patient", data);

  static createAuthentication = (data = {}) =>
    backendRequestPost("/logging/create/authentication", data);

  static createLogout = (data = {}) =>
    backendRequestPost("/logging/create/logout", data);

  static showIntegrationMIS = (data = {}) =>
    backendRequestPost("/logging/show/integrationMIS", data);

  static gipSearch = (data = {}) =>
    backendRequestPost("/logging/gip/search", data);

}

export default Logger;
