/**
 * глобальный информаторий
 *
 *
 *
 */

import { observable, action } from 'mobx';
import { createId } from 'GUI_MAIN/Utils/createId';


export const TOAST_TYPES = {
    INFO    : 'TOAST_INFO',
    WARNING : 'TOAST_WARNING',
    ERROR   : 'TOAST_ERROR',
    DEFAULT : 'TOAST_DEFAULT'
};



class ToasterStoreClass {

    /**
     *
     */
    @observable
    data = {};

    @observable
    autoClose = true

    /**
     *
     */
    @action
    addToast(message, options = {}) {

        var needFlash = this.removeToastWithMessage(message);

        const id = createId();

        this.data[id] = {
            message: message,
            type: options.type || TOAST_TYPES.INFO,
            flash: needFlash,
        };

        if (needFlash) {
            setTimeout( () => this.clearFlash(id), 42);
        }

        const timeout = options.timeout || (options.type === TOAST_TYPES.ERROR ? 3000 : 7000);

        if (options.autoClose) {
            this.data[id].timer = new Timer(() => this.removeToast(id), timeout);
            //setTimeout( () => this.removeToast(id), timeout);
        }
    }

    /**
     *
     */
    @action
    clearFlash(id) {
        if (!this.data[id]) {
            return;
        }
        this.data[id].flash = false;
    }

    /**
     *
     */
    @action
    removeToast(id) {
        delete(this.data[id]);
    }

    @action
    removeAllToasts() {
        this.data = {};
    }

    /**
     *
     */
    info(message, options) {
        this.addToast(message, { ...{...options, autoClose: this.autoClose}, type: TOAST_TYPES.INFO });
    }

    /**
     *
     */
    warning(message, options) {
        this.addToast(message, { ...{...options, autoClose: this.autoClose}, type: TOAST_TYPES.WARNING });
    }

    /**
     *
     */
    error(message, options) {
        this.addToast(message, { ...{...options, autoClose: this.autoClose || false}, type: TOAST_TYPES.ERROR });
    }

    default(message, options) {
        this.addToast(message, { ...{...options, autoClose: this.autoClose || false}, type: TOAST_TYPES.DEFAULT});
    }

    /**
     * Удаление уведомлений с указанным сообщением
     * @param message
     */
    removeToastWithMessage = message => {

        var removedCount = 0;
        Object.keys(this.data).forEach( key => {
            if (this.data[key].message == message) {
                this.removeToast(key);
                removedCount++;
            }
        });

        return removedCount;
    }
}

const ToasterStore = new ToasterStoreClass();

export { ToasterStore };

function Timer(callback, delay) {
    var args = arguments,
        self = this,
        timer, start;

    this.clear = function () {
        clearTimeout(timer);
    };

    this.pause = function () {
        this.clear();
        delay -= new Date() - start;
    };

    this.resume = function () {
        start = new Date();
        timer = setTimeout(function () {
            callback.apply(self, Array.prototype.slice.call(args, 2, args.length));
        }, 7000);
    };

    this.resume();
}
