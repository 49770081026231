/**
 * пока пользователь не залогинен, отправляем запросы с basic-auth согласно конфигу
 * когда залогинен, по bearer
 */
import _get from "lodash/get";
import { observable, action, computed } from 'mobx';

import { basicRequestGet } from 'MODEL_STORE/DataSource/Requests/basicRequestGet';
import { basicRequestPost } from 'MODEL_STORE/DataSource/Requests/basicRequestPost';

import { User } from 'MODEL_STORE/FHIR/Resources/User';
import { Practitioner } from 'MODEL_STORE/FHIR/Resources/Practitioner';
import { PractitionerRole } from 'MODEL_STORE/FHIR/Resources/PractitionerRole';

import {Organization} from "../../FHIR/Resources/Organization";
import { ToasterStore } from 'MODEL_STORE/DataSource/Stores/ToasterStore';
import {EMAIL_REGEX} from "../Constants/regex";
import { config } from 'config';
import { Translator } from "eo-locale";
import { HeaderStore } from "./HeaderStore";
import { locales } from "Locale";
import Logger from "../../../logger";
import {
    AUTH_TOKEN_STORAGE_KEY,
    ACTIVE_ROLE_ID_STORAGE_KEY,
} from "./AuthStoreDataClass";



export class AuthStoreActionsClass {

    @observable
    currentUserData;

    // сюда сложим history из роутера
    browserHistory = null;

    constructor(authStoreData) {
        this.setCurrentUserData(authStoreData);
    }

    @action
    setCurrentUserData(data) {
        this.currentUserData = data
    }

    historyConnector = (history) => {
        console.log("connecting histories...");
        this.browserHistory = history;
    }

    /**
     * Записываем в сессию выбранную роль
     */
    changeRoleId = (role_id, source) => {
        const data = {
            role_id: role_id,
            source: source,
        }
        return basicRequestPost('/api/mm/Role', data)
            .then(response => {
                if (response.data.error) {
                    const translator = new Translator(HeaderStore.locale, locales);
                    ToasterStore.error(translator.translate('TT015730', { message: response.data.message }));
                }
                else {
                    this.testCurrentLogged();
                }
            })
    };

    /**
     * меняем токен, присланный МИС, на "нормальный", который будет рефрешиться
     */
    switchSessionIfNeeded = async() => {

        return false;

        // на этом проекте не нужно

    }

    /**
     * перезапуск сессии FCSPMM-528
     * нужен при обнаружении входа в другой вкладке под другим пользователем
     */
    @action
    reloadSession = () => {
        window.sessionStorage.removeItem(AUTH_TOKEN_STORAGE_KEY);
        window.location.reload();
    }

    /**
     * получаем информацию о текущем залогиненном пользователе, полный цикл (инфа о сессии + запрос пользователя)
     */
    @action
    testCurrentLogged = () => {

        this.currentUserData.setReady(false);
        return this.switchSessionIfNeeded()
            .then(() => { return basicRequestGet('/api/mm/userinfo')})
            .then(response => {
                const {data} = response.data;
                const {user, practitioner, organizations, roles, active_role_id, active_organization_id} = data;
                this.currentUserData.setUser(new User(user));
                this.currentUserData.setPerson(new Practitioner(practitioner));

                if (roles) {
                    this.currentUserData.setRoles(roles.map(role => {
                        return new PractitionerRole(role);
                    }));
                }
                if (organizations) {
                    this.currentUserData.setOrganizations(organizations.map(org => {
                        return new Organization(org);
                    }));
                }
                if (active_role_id) {
                    this.currentUserData.setActiveRoleId(active_role_id);
                    this.currentUserData.setActiveOrganizationId(active_organization_id);

                    window.localStorage.setItem(ACTIVE_ROLE_ID_STORAGE_KEY, active_role_id);
                    window.sessionStorage.setItem(ACTIVE_ROLE_ID_STORAGE_KEY, active_role_id);
                }
            })
            .then(() => {
                if (this.currentUserData.isRoot() || this.currentUserData.isFullLogin()){
                    return basicRequestGet('/api/mm/access/general', {})
                        .then(response => {
                            this.currentUserData.setPermission(response.data.data.allowed);
                        });
                }
            })
            .then( () => {
                if (this.currentUserData.isRoot() || this.currentUserData.isFullLogin()) {
                    this.currentUserData.setReady(true);
                    this.currentUserData.setSelectRole(true);
                }
                else {
                    this.currentUserData.setSelectRole(true);
                }
            })
    };

    /**
     * загрузка ролей, если передан идентификатор текущего сотрудника
     */
    loadRolesIfCurrentPractitioner = (practitionerId) => {
        if( _get(this.currentUserData, "person.id") == practitionerId ) {
            return this.loadRoles();
        }
    }

    /**
     * загрузка информации о ролях текущего сотрудника
     */
    loadRoles = () => {
        return new Promise(resolve => {
            if ( ! this.currentUserData.person ) {
                return resolve();
            }
            return basicRequestPost(`/PractitionerRole/_search`, {
                practitioner: this.currentUserData.person.id
            }).then(response => {
                this.currentUserData.setRoles(response.data.entry.map(entry => {
                    return new PractitionerRole(entry.resource);
                }));
                return resolve();
            }).catch(error => {
                console.warn(error);
            });
        });
    }

    /**
     * проверяем логин пароль, при удачном входе сохраняем данные
     * при авторизации передаем basic-auth клиента и в теле логин и пароль пользователя
     * клиент должен иметь доступный grant-type password
     *
     */
    login(login, password) {

        this.currentUserData.clear();

        return this.currentUserData.getToken(login, password)
            .then(this.testCurrentLogged);
    }

    /**
     * Очистка локального хранилища от Контекста Емиаса
     */
    clearLocalStorageContext = () => {
        localStorage.removeItem('contextTargetConsilium');
        localStorage.removeItem('emiasContext');
        localStorage.removeItem('contextConsilium');
        localStorage.removeItem('contextId');
        localStorage.removeItem('contextPatientId');
        localStorage.removeItem('contextConditions');
        localStorage.removeItem('contextTargetConsilium');
        localStorage.removeItem('ServiceRequestCandidates');
    };

    /**
     * options.afterUrl = куда редиректить после разлогина
     */
    logout(options = {}) {

        try {
            Logger.createLogout();
        } catch(e) {
            console.warn("error logging logout", e)
        }
        return basicRequestPost('/openid/logout', {"refresh_token": this.currentUserData.loadRefreshToken()})
            .then( () => {
                this.clearLocalStorageContext();
                sessionStorage.removeItem(AUTH_TOKEN_STORAGE_KEY);
                this.currentUserData.clear();

                const afterUrl = options.afterUrl || "/";

                if (this.browserHistory) {
                    this.browserHistory.push(afterUrl);
                } else {
                    console.warn("saved history missing");
                }
            })
            .catch( e => console.warn('logout exception:', e))
            ;
    }

    openPageChangeAuthRole = () => {
        this.currentUserData.setReady(false);
        this.currentUserData.setSelectRole(true);
    };

    startOpenId = async () => {

        const urlParams = new URLSearchParams(window.location.search);

        // сначала нужно вырезать некоторые служебные параметры
        if (urlParams.get("error-details")) {
            console.warn("deleting search param \"error-details\" from query");
            urlParams.delete("error-details");
        }
        const locationTarget =  window.location.pathname + (urlParams.length ? "?" + urlParams.toString() : "")
        const encodedLink = btoa(locationTarget)
        let url = `/openid/start-auth?after=${encodedLink}`;

        const require = urlParams.get('require');

        if (require) {
            url = `${url}&require=${require}`;
        }

        const source = urlParams.get('source');
        if (source) {
            url = `${url}&source=${source}`;
        }

        await basicRequestGet(url, { headers: { "Authorization": "" }})
            .then(response => {
                const openIdLoginPage = response.data;
                window.location.href = openIdLoginPage;
            });
    };

    ///////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////

    getRoles() {
        return this.currentUserData.roles.map(p => p.code[0].coding[0].code);
    }

    /**
     * Есть ли в списке ролей - Старший врач-химиотерапевт
     */
    @computed
    get hasArmHlChemotherapist() {
        return !!this.currentUserData.roles.find(e => e.isArmHlChemotherapist);
    }

    /**
     * Есть ли в списке ролей - Администратор МО
     */
    @computed
    get hasArmAdministrator() {
        return !!this.currentUserData.roles.find(e => e.isArmAdministrator);
    }

    /**
     *
     */
    @action
    requestPasswordReset = async ({email, history,}) => {
        const translator = new Translator(HeaderStore.locale, locales);
        if( ! EMAIL_REGEX.test(email) ) {
            this.currentUserData.setPasswordRequestActivityEmailFieldError(true)
            return this.currentUserData.setResetPasswordRequestError(translator.translate('SS902630'))
        }

        this.currentUserData.setResetPasswordRequestError(null)
        this.currentUserData.setPasswordRequestActivityEmailFieldError(false)
        this.currentUserData.setResetPasswordRequestInProgress(true)

        try {
            await basicRequestGet(`/api/integrations/element/password/reset/request/?email=${email}`)

        } catch (error) {
            this.currentUserData.setResetPasswordRequestInProgress(false)

            console.error(error.response.data)

            if (error.response.data.status == 'email_not_found')  {
                return this.currentUserData.setResetPasswordRequestError(`${translator.translate('SS902638')} ${translator.translate('SS902639')}`)
            }
            if (error.response.data.status == 'email_send_error')  {
                return this.currentUserData.setResetPasswordRequestError(translator.translate('TT017890'))
            }

            return this.currentUserData.setResetPasswordRequestError(translator.translate('TT017900'))
        }

        this.currentUserData.clear()
        this.clearLocalStorageContext()

        ToasterStore.removeAllToasts()
        ToasterStore.info(translator.translate('SS902647'));
        history.push('/');
    };


    /**
     * Смена пароля
     */
    setInputForChangePassword = async ({key, email, history,}) => {
        const translator = new Translator(HeaderStore.locale, locales);
        if( !this.currentUserData.inputForChangePassword1 || this.currentUserData.inputForChangePassword1.trim().length < 6 ||
          !this.currentUserData.inputForChangePassword2 || this.currentUserData.inputForChangePassword2.trim().length < 6 ) {
            return this.currentUserData.setInputForChangePasswordError(translator.translate('TT017910'))
        }

        const password1 = this.currentUserData.inputForChangePassword1.trim()
        const password2 = this.currentUserData.inputForChangePassword2.trim()

        if (password1 != password2) {
            return this.currentUserData.setInputForChangePasswordError(translator.translate('TT017920'))
        }

        this.currentUserData.setInputForChangePasswordError(null)
        this.currentUserData.setResetPasswordRequestInProgress(true)

        try {
            await basicRequestGet(
              `/api/integrations/element/password/reset/proceed/?email=${email}&key=${key}&password=${password1}`
            )

        } catch (error) {
            this.currentUserData.setResetPasswordRequestInProgress(false)

            console.error(error.response.data)

            switch (error.response.data.status) {
                case 'email_not_found':
                    return this.currentUserData.setInputForChangePasswordError(translator.translate('TT017930'))
                case 'password_error':
                    return this.currentUserData.setInputForChangePasswordError(translator.translate('TT017910'))
                case 'key_not_found':
                    return this.currentUserData.setInputForChangePasswordError(translator.translate('TT017940'))
                case 'users_not_found':
                case 'users_each_empty':
                    return this.currentUserData.setInputForChangePasswordError(translator.translate('TT017950'))
            }

            return this.currentUserData.setInputForChangePasswordError(translator.translate('TT017900'))
        }

        this.currentUserData.clear()
        this.clearLocalStorageContext()

        ToasterStore.removeAllToasts()
        ToasterStore.info(translator.translate('TT015750'));
        history.push('/');
    }
}
